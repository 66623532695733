import * as React from 'react'
import Layout from "../components/layout";
import LatestGrid from "../components/image-layouts/latestGrid";
import {graphql, Link} from "gatsby";
import DetailPage from "../components/detailPage";

const MiscPage = ({data}) => (
    <Layout>
        <h1 className={'text-9xl mb-6'}>Misc</h1>
        <DetailPage data={data} />
    </Layout>
)

export default MiscPage;

export const query = graphql`
    query {
        all: allFile(
            filter: {
                extension: {regex: "/(jpg)|(jpeg)/"},
                relativeDirectory: {eq: "photography/misc"}
            }
            sort: {order: DESC, fields: modifiedTime}
        ) {
            nodes {
                childImageSharp {
                    gatsbyImageData(width: 1600, placeholder: BLURRED)
                    fluid {
                        src
                        originalName
                    }
                    resize {
                        aspectRatio
                    }
                    fields {
                        exif {
                            raw {
                                image {
                                    Make
                                    Model
                                }
                                exif {
                                    FNumber
                                    FocalLengthIn35mmFormat
                                    ISO
                                    LensMake
                                    LensModel
                                    ShutterSpeedValue
                                    FocalLength
                                }
                            }
                        }
                    }
                }
                relativeDirectory
            }
        }
    }
`;
export const Head = () => <title>Misc - Rol John Torralba</title>
